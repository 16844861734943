import React, { useState } from "react";
import MultiSelect from "../components/multiselect";
import { useDispatch, useSelector } from "react-redux";
import {
  refreshRagioneSociale,
  Selectors,
  selectRagioneSociale,
  selectPVIs,
  State,
  exportExcel,
  uploadExcel,
  selectMercati,
  modifyOnline,
  cancelModifyOnline,
  selectMGPDate,
  clearData,
  uploadClose,
  selectMIDate,
  selectMGPGranularity,
} from "../redux/modules/MGP";
import { Selectors as TideSelectors } from "../redux/modules/tide";
import { toOption, fold, anyInProgess } from "../utils/request";
import { pipe } from "fp-ts/lib/pipeable";
import * as R from "ramda";
import * as O from "fp-ts/lib/Option";
import * as A from "fp-ts/lib/Array";
import RadioButton from "../components/radioButton";
import DateRange from "../components/dateRange";
import Loader from "../components/loader";
import EditMgp from "./EditMGP";
import { format } from "date-fns";
import Protected from "../protectedComponent";
import permissions from "../permissions";
import DrapDropFileUpload from "../components/dragFile";
import Datepicker from "../components/datePicker";
import NotifcationModal from "../components/notifcationModal";
import SingleSelect from "../components/singleSelectMain";
import PageOnLoad from "../components/pageOnLoad";
import ConfigModal from "../config/components/configModal";
import { setErrorAction } from "../redux/modules/errorHandler";
type MarketType = "MGP" | "MI1" | "MI2" | "MI3" | "MI4" | "MI5" | "MI6" | "MI7";
export default function MGP() {
  const [files, setFiles] = React.useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [choosenMarket, setChoosenMarket] = useState<MarketType>("MGP");
  const [selectedOption, setSelectedOption] = useState<
    "Orario" | "Quartorario" | "Misto"
  >("Orario");
  const [actionType, setActionType] = useState("");
  const dispatch = useDispatch();
  const mgp = useSelector(Selectors.all);
  const switchDate = new Date(useSelector(TideSelectors.tideDate));

  const fornituraFlag = pipe(
    toOption(mgp.contractInfos),
    O.fold(() => {}, (x) => x),
    (x) => R.pathOr({}, [0, "fornitura", 0], x),
    (x) =>
      R.pick(
        [
          "caricamentoMGP",
          "caricamentoMGPGradini",
          "caricamentoMI",
          "caricamentoMIGradini",
        ],
        x
      ),
    (x) => R.values(x),
    (x) => R.any(R.equals(true), x)
  );

  React.useEffect(() => {
    dispatch(refreshRagioneSociale);

    return () => {
      dispatch(clearData);
    };
  }, [dispatch]);

  const handleShowModal = (type: string) => {
    setActionType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value as "Orario" | "Quartorario" | "Misto");
  };
  const handleMarketChange = (market: MarketType) => {
    dispatch(selectMercati(market));
    setChoosenMarket(market);
  };
  const handleVaiClick = () => {
    const isPostTide = !isOnlyOrarioRange(mgp.dateRange);
    dispatch(selectMGPGranularity(selectedOption));
    if (selectedOption === "Orario") {
      if (actionType === "modifyOnline") {
        dispatch(modifyOnline);
      } else if (actionType === "exportExcel") {
        dispatch(exportExcel(selectedOption, isPostTide));
      }
    } else if (selectedOption === "Quartorario") {
      if (actionType === "modifyOnline") {
        dispatch(modifyOnline);
      } else if (actionType === "exportExcel") {
        dispatch(exportExcel(selectedOption, isPostTide));
      }
    }
    handleCloseModal();
  };

  const isDateAcrossSwitchDate = (
    dateRange: { start: string; end: string } | null
  ) => {
    if (!dateRange) return false;
    const startDate = new Date(dateRange.start);
    const endDate = new Date(dateRange.end);

    if (
      startDate.getTime() === switchDate.getTime() &&
      endDate.getTime() === switchDate.getTime()
    ) {
      return false;
    }
    return startDate < switchDate && switchDate <= endDate;
  };

  const isDateRangeValidForTide = (
    dateRange: { start: string; end: string } | null
  ) => {
    if (!dateRange) return false;

    const startDate = new Date(dateRange.start);
    const endDate = new Date(dateRange.end);

    if (
      startDate <= switchDate &&
      switchDate <= endDate &&
      endDate > switchDate
    ) {
      return false;
    }
    return true;
  };
  const isOnlyOrarioRange = (
    dateRange: { start: string; end: string } | null
  ) => {
    if (!dateRange) return true;
    const endDate = new Date(dateRange.end);
    const startDate = new Date(dateRange.start);
    if (choosenMarket !== "MGP") {
      return startDate < switchDate;
    }
    if (endDate < switchDate) {
      return true;
    }
  };

  const handleButtonClick = (type: string) => {
    const isPostTide = !isOnlyOrarioRange(mgp.dateRange);
    if (isDateAcrossSwitchDate(mgp.dateRange)) {
      dispatch(
        setErrorAction({
          details: {
            title: "Error",
            message: mgp.dateRange
              ? "A causa della transizione regolatoria vi chiediamo di inserire una previsione separata per le competenze 2024 e 2025."
              : "Per favore seleziona un intervallo di date valido",
            btnTitle: "Close",
          },
        })
      );
    } else {
      //IF MI is slelected there is no need to ask for orario quartorario.
      if (choosenMarket !== "MGP") {
        const gran = !isPostTide ? "Orario" : "Quartorario";
        dispatch(selectMGPGranularity(gran));
        if (type === "modifyOnline") {
          dispatch(modifyOnline);
        } else if (type === "exportExcel") {
          dispatch(exportExcel(gran, isPostTide));
        }
      } else {
        //if mgp
        if (isPostTide) {
          handleShowModal(type);
        } else {
          if (type === "modifyOnline") {
            dispatch(modifyOnline);
          } else if (type === "exportExcel") {
            dispatch(exportExcel("Orario", !isOnlyOrarioRange(mgp.dateRange)));
          }
        }
      }
    }
  };
  const modalBody = (
    <div className="radio-container">
      <div className="radio-option">
        <input
          type="radio"
          id="orario"
          name="option"
          value="Orario"
          onChange={handleOptionChange}
          className="radio-input"
        />
        <label htmlFor="orario" className="radio-label">
          Orario
        </label>
      </div>
      <div className="radio-option">
        <input
          type="radio"
          id="quartorario"
          name="option"
          value="Quartorario"
          onChange={handleOptionChange}
          className="radio-input"
        />
        <label htmlFor="quartorario" className="radio-label">
          Quartorario
        </label>
      </div>
    </div>
  );

  return fold(mgp.modifyOnline, {
    fail: () => null,
    inProgress: () => (
      <>
        <Loader
          load={anyInProgess([
            mgp.ragioneSociale,
            mgp.pvis,
            mgp.market,
            mgp.contractInfos,
            mgp.modifyOnline,
            mgp.esitoBiddingComputed,
          ])}
        />
        <div className="container">
          <div
            className="backButton"
            onClick={() => dispatch(cancelModifyOnline)}
          >
            <i className="fas fa-arrow-circle-left" />
          </div>
        </div>
      </>
    ),
    success: (data) =>
      pipe(
        toOption(mgp.contractInfos),
        O.fold(
          () => null,
          (contractInfo) => (
            <EditMgp data={data} contractInfos={contractInfo}></EditMgp>
          )
        )
      ),
    notStarted: () => (
      <>
        <PageOnLoad />
        <div className="container d-flex flex-column margin-bottom-18">
          <NotifcationModal
            open={mgp.uploadModify}
            close={() => dispatch(uploadClose())}
            title="Upload Successful"
            message={
              <>
                {mgp.isCapacityMarketSatisfied ? null : (
                  <div className="isCapacityMarketSatisfied">
                    Rischio Capacity Market Non Rispettato
                  </div>
                )}
                <div>MGP & MI Upload Successful</div>
              </>
            }
          />
          <Loader
            load={anyInProgess([
              mgp.ragioneSociale,
              mgp.pvis,
              mgp.market,
              mgp.contractInfos,
              mgp.modifyOnline,
              mgp.esitoBiddingComputed,
            ])}
          />
          <div className="main-panel">
            <div className="left-panel regioneSelector">
              <label className="form-group">
                <span className="form-label">Ragione Sociale</span>

                {pipe(
                  mgp.selectedRagioneSociale,
                  O.fold(
                    () => <div className="text-secondary"></div>,
                    (r) => (
                      <SingleSelect
                        containsFilter
                        value={[
                          { value: r.partitaIva, title: r.ragioneSociale },
                        ]}
                        update={(x: any[]) =>
                          dispatch(
                            selectRagioneSociale(R.pathOr(
                              null,
                              [0, "info"],
                              x
                            ) as any)
                          )
                        }
                        options={pipe(
                          toOption(mgp.ragioneSociale),
                          O.fold(
                            () => [],
                            (ragione) =>
                              R.map(
                                (x: any) => ({
                                  value: x.partitaIva,
                                  title: x.ragioneSociale,
                                  info: x,
                                }),
                                ragione
                              )
                          )
                        )}
                      />
                    )
                  )
                )}
              </label>
              <div className="form-group full-height">
                {pipe(
                  toOption(mgp.pvis),
                  O.fold(
                    () => (
                      <div className="selectionContainerEmpty">
                        SELEZIONA A RAGIONE
                      </div>
                    ),
                    (pvis) => (
                      <div className="">
                        <MultiSelect
                          options={pvis.map((x) => ({
                            key: x.nome,
                            pvi: x.pvi,
                            checked: !!mgp.selectedPVIs.find(
                              (p) => x.nome === p.nome
                            ),
                          }))}
                          onChange={(checkedPvis) =>
                            pipe(
                              checkedPvis,
                              A.filterMap((x) =>
                                x.checked ? O.some(x.key) : O.none
                              ),
                              (selected) =>
                                pvis.filter((x) => selected.includes(x.nome)),
                              selectPVIs,
                              dispatch
                            )
                          }
                        ></MultiSelect>
                      </div>
                    )
                  )
                )}
              </div>
            </div>
            <div className="verticalRule"></div>
            <label className="form-group right-panel text-uppercase">
              {pipe(
                mgp.availableMercati,
                O.fold(
                  () => (
                    <div className="selectionContainerMGPHolder">
                      Seleziona a pvi
                    </div>
                  ),
                  (mercati) => (
                    <div className="selectionContainerMGP">
                      <span className="form-label">Scegli il mercato</span>
                      {mercati.map((x) => (
                        <RadioButton
                          checked={pipe(
                            mgp.selectedMercati,
                            O.fold(() => false, (sel) => sel === x)
                          )}
                          key={x}
                          label={x}
                          name={x}
                          update={() => handleMarketChange(x)}
                        />
                      ))}
                    </div>
                  )
                )
              )}
            </label>
          </div>

          <div className="form-group text-uppercase">
            {pipe(
              mgp.selectedPVIs,
              (pvis) => (pvis.length > 0 ? O.some(pvis) : O.none),
              O.fold(
                () => (
                  <div className="selectionContainerDatePicker">
                    {fornituraFlag
                      ? "Seleziona il mercato di interesse"
                      : "L'IMPIANTO SELEZIONATO NON E' ABILITATO AD EFFETTUARE CARICAMENTI"}
                  </div>
                ),
                () =>
                  foldDate(mgp.dateSelect, {
                    none: () => (
                      <div className="selectionContainerDatePicker">
                        {fornituraFlag
                          ? "Seleziona il mercato di interesse"
                          : "L'IMPIANTO SELEZIONATO NON E' ABILITATO AD EFFETTUARE CARICAMENTI"}
                      </div>
                    ),
                    MI: (s) => {
                      if (!mgp.dateRange) {
                        dispatch(selectMIDate(s));
                      }
                      return (
                        <div className="miDatepicker">
                          <span className="form-label">Seleziona Periodo</span>
                          <Protected
                            permission={[
                              permissions.misurePrezziBiddingPVIWriteAdmin,
                            ]}
                            component={() => (
                              <Datepicker
                                date={new Date(s)}
                                className="input-height form-control fa fa-calendar-alt-red-after"
                                onDateChange={(value) =>
                                  R.isNil(value)
                                    ? true
                                    : dispatch(
                                        selectMIDate(
                                          format(value, "yyyy-MM-dd")
                                        )
                                      )
                                }
                              />
                            )}
                            fallback={() => (
                              <Datepicker
                                date={new Date(s)}
                                disabled
                                className="input-height form-control fa fa-calendar-alt-red-after"
                                onDateChange={() => true}
                              />
                            )}
                          />
                        </div>
                      );
                    },
                    MGP: (start, end, min) => {
                      if (!mgp.dateRange) {
                        dispatch(selectMGPDate(start, end));
                      }
                      return (
                        <div className="center-vertical">
                          <div>
                            <span className="form-label">
                              Seleziona Periodo
                            </span>
                            <Protected
                              permission={[
                                permissions.misurePrezziBiddingPVIWriteAdmin,
                              ]}
                              component={() => (
                                <DateRange
                                  startDate={new Date(start)}
                                  onStartDateChange={(value) =>
                                    dispatch(
                                      selectMGPDate(
                                        format(value, "yyyy-MM-dd"),
                                        end
                                      )
                                    )
                                  }
                                  endDate={new Date(end)}
                                  onEndDateChange={(value) =>
                                    dispatch(
                                      selectMGPDate(
                                        start,
                                        format(value, "yyyy-MM-dd")
                                      )
                                    )
                                  }
                                />
                              )}
                              fallback={() => {
                                const marketStates = R.pathOr(
                                  [],
                                  ["market", "value", "marketStates"]
                                )(mgp);
                                const startLimit = R.pipe<any, any, any>(
                                  R.find(R.propEq("mercatiBidding", "MGP")),
                                  R.propOr(null, "validDataStart")
                                )(marketStates);
                                const endLimit = R.pipe<any, any, any>(
                                  R.find(R.propEq("mercatiBidding", "MGP")),
                                  R.propOr(null, "validDataEnd")
                                )(marketStates);

                                return (
                                  <DateRange
                                    startDate={new Date(start)}
                                    onStartDateChange={(value) =>
                                      dispatch(
                                        selectMGPDate(
                                          format(value, "yyyy-MM-dd"),
                                          end
                                        )
                                      )
                                    }
                                    endDate={new Date(end)}
                                    onEndDateChange={(value) =>
                                      dispatch(
                                        selectMGPDate(
                                          start,
                                          format(value, "yyyy-MM-dd")
                                        )
                                      )
                                    }
                                    maxDate={
                                      endLimit ? new Date(endLimit) : null
                                    }
                                    minDate={
                                      startLimit ? new Date(startLimit) : null
                                    }
                                  />
                                );
                              }}
                            />
                          </div>
                        </div>
                      );
                    },
                  })
              )
            )}
          </div>
          <div className="spacer" />
          <div className="d-flex justify-content-center align-items-center">
            <Protected
              permission={permissions.mgpMiWritePermissions}
              component={() => (
                <div
                  title={
                    R.path(["value"])(mgp.selectedMercati) ||
                    mgp.selectedPVIs.length === 0 ||
                    mgp.selectedPVIs.length > 1
                      ? mgp.selectedPVIs.length === 0
                        ? "Seleziona almeno un impianto"
                        : mgp.selectedPVIs.length > 1
                        ? "Seleziona un solo impianto per effettuare la modifica"
                        : ""
                      : "Seleziona un mercato"
                  }
                >
                  <button
                    className="btn btn-danger rounded-pill mx-2"
                    onClick={() => handleButtonClick("modifyOnline")}
                    disabled={
                      mgp.selectedPVIs.length !== 1 ||
                      O.isNone(mgp.selectedMercati)
                    }
                  >
                    Modifica Online
                  </button>
                  oppure
                </div>
              )}
            />
            <div
              title={
                R.path(["value"])(mgp.selectedMercati) ||
                mgp.selectedPVIs.length === 0
                  ? mgp.selectedPVIs.length === 0
                    ? "Seleziona almeno un impianto"
                    : ""
                  : "Seleziona un mercato"
              }
            >
              <button
                className="btn btn-danger rounded-pill mx-2"
                onClick={() => handleButtonClick("exportExcel")}
                disabled={O.isNone(mgp.selectedMercati)}
              >
                Esporta il file XLSX
              </button>
            </div>
          </div>
          <div className="spacer" />
          <Protected
            permission={permissions.mgpMiWritePermissions}
            component={() => (
              <>
                <DrapDropFileUpload
                  fileList={files}
                  update={setFiles}
                  disabled={O.isNone(mgp.selectedMercati)}
                  upload={(x: any) =>
                    dispatch(
                      uploadExcel(x[0], !isOnlyOrarioRange(mgp.dateRange))
                    )
                  }
                  validFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
                <div className="spacer" />
              </>
            )}
          />
        </div>
        <ConfigModal
          openModal={showModal}
          modalTitle="Seleziona Granularità"
          modalBody={modalBody}
          disableApplyBtn={!selectedOption}
          closeModal={handleCloseModal}
          commitChanges={handleVaiClick}
        />
      </>
    ),
  });
}

function foldDate<a>(
  date: State["dateSelect"],
  {
    none,
    MI,
    MGP,
  }: {
    none: () => a;
    MI: (s: string) => a;
    MGP: (start: string, end: string, min: string) => a;
  }
): a {
  return pipe(
    date,
    O.fold(none, function(x) {
      switch (x.tag) {
        case "MI":
          return MI(x.date);
        case "MGP":
          return MGP(x.start, x.end, x.min);
      }
    })
  );
}
