import { combineEpics } from "redux-observable";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { wrapExhaustiveReducer } from "../utils/redux";
import {
  key as authKey,
  reducer as authReducer,
  epic as authEpic,
} from "./modules/authentication";
import {
  key as MGPKey,
  reducer as MGPReducer,
  epic as MGPEpic,
  initialState as MGPInitialState,
} from "./modules/MGP";
import {
  key as EsitiKey,
  reducer as EsitiReducer,
  epic as EsitiEpic,
  initialState as EsitiInitialState,
} from "./modules/Esiti";
import {
  key as enumKey,
  reducer as enumReducer,
  epic as enumEpic,
} from "./modules/enums";
import {
  reducer as configReducer,
  epic as configEpic,
} from "./modules/configs/index";
import {
  reducer as marketsReducer,
  epic as marketsEpic,
} from "./modules/markets/index";
import {
  reducer as managementReducer,
  epic as managementEpic,
} from "./modules/management/index";
import {
  reducer as ProductionDataReducer,
  epic as ProductionDataEpic,
} from "./modules/productionData";
import {
  key as errorHandlerKey,
  reducer as errorHandlersReducer,
} from "./modules/errorHandler";
import { reducer as auditsReducer, epic as auditsEpic } from "./modules/audits";
import {
  key as DisponibilitaKey,
  reducer as DisponibilitaReducer,
  epic as DisponibilitaEpic,
  initialState as DisponibilitaInitialState,
} from "./modules/Disponibilita";
import {
  key as CombustibiliKey,
  reducer as CombustibiliReducer,
  epic as CombustibiliEpic,
  initialState as CombustibiliInitialState,
} from "./modules/Combustibili";
import { epic as appInsightsEpic } from "./modules/appInsights/appInsights";
import {
  key as cridaKey,
  Reducer as cridaReducer,
  epic as cridaEpic,
} from "./modules/crida";
import {
  key as tideKey,
  Reducer as tideReducer,
  epic as tideEpic,
} from "./modules/tide";

export const rootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    [authKey]: authReducer,
    [MGPKey]: wrapExhaustiveReducer(MGPReducer, MGPInitialState),
    [enumKey]: enumReducer,
    [enumKey]: enumReducer,
    [errorHandlerKey]: errorHandlersReducer,
    [cridaKey]: cridaReducer,
    [tideKey]: tideReducer,
    config: configReducer,
    markets: marketsReducer,
    management: managementReducer,
    audits: auditsReducer,
    [DisponibilitaKey]: wrapExhaustiveReducer(
      DisponibilitaReducer,
      DisponibilitaInitialState
    ),
    [CombustibiliKey]: wrapExhaustiveReducer(
      CombustibiliReducer,
      CombustibiliInitialState
    ),
    [EsitiKey]: wrapExhaustiveReducer(EsitiReducer, EsitiInitialState),
    productionData: ProductionDataReducer,
  });

export const rootEpic = combineEpics(
  appInsightsEpic,
  authEpic,
  MGPEpic,
  enumEpic,
  configEpic,
  auditsEpic,
  marketsEpic,
  DisponibilitaEpic,
  CombustibiliEpic,
  EsitiEpic,
  managementEpic,
  ProductionDataEpic,
  tideEpic,
  cridaEpic
);
