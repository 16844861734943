import { combineEpics } from "redux-observable";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Dependancies } from "../storeTypes";

export const key = "tideDate";
export enum actionTypes {
  SET_TIDEDATE = "met/tideDate/SET_TIDEDATE",
}

type Actions = {
  type: actionTypes.SET_TIDEDATE;
  tideDate: any;
};

export function Reducer(state: any = null, action: Actions): any {
  switch (action.type) {
    case actionTypes.SET_TIDEDATE:
      return action.tideDate;
    default:
      return state;
  }
}

const setTideDateAction = (tideDate: any): Actions => ({
  type: actionTypes.SET_TIDEDATE,
  tideDate,
});

const inittideDateEpic = (
  action$: Observable<any>,
  _: any,
  deps: Dependancies
) =>
  action$.pipe(
    filter((x) => x.type === "INIT"),
    map(() =>
      setTideDateAction({
        tideDate: deps.tideDate,
      })
    )
  );

export const epic = combineEpics(inittideDateEpic);

export type TideDateState = {
  tideDate: string;
};
export const Selectors = {
  all: (state: any): TideDateState => state[key],
  tideDate: (state: any): string => state[key].tideDate,
};
