import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { createBrowserHistory as createHistory } from "history";
import App from "./App";
import CreateStore from "./redux/configureStore";
import { getEnv } from "./environment";
import * as RxOp from "rxjs/operators";
import "bootstrap/dist/css/bootstrap.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./styles/avenir-font-file.css";
import { ajax } from "rxjs/ajax";
import * as E from "fp-ts/lib/Either";
import { tryCatch } from "fp-ts/lib/TaskEither";
import * as t from "io-ts";
import { of } from "rxjs";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Auth0Client } from "@auth0/auth0-spa-js";
import { cleanUpLegacyStorage } from "./cleanUpStorage";
import { PathReporter } from "io-ts/lib/PathReporter";

cleanUpLegacyStorage();

const createEnhancedHistory = () => {
  let history: any;
  const getUserConfirmation = (mes: any, cb: any): any =>
    history.getUserConfirmation(mes, cb);
  history = createHistory({ getUserConfirmation });
  history.getUserConfirmation = (mess: any, cb: any) =>
    cb(window.confirm(mess));
  return history;
};
export const history = createEnhancedHistory();

getEnv
  .pipe(
    RxOp.map((env) => {
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: env.appinsightsKey,
          enableCorsCorrelation: true,
          correlationHeaderExcludedDomains: [env.domain],
          isCookieUseDisabled: true,
        },
      });
      appInsights.loadAppInsights();
      const authModule = new Auth0Client({
        domain: env.domain,
        clientId: env.clientID,
        useRefreshTokens: true,
        authorizationParams: {
          audience: env.audience,
          scope:
            "openid profile email dealCapture:write dealCapture:read audit:read esitoBiddingPVI:read esitoBiddingUP:read esitoBiddingPVI:write esitoBiddingUP:write misurePrezziBiddingPVI:write:admin disponibilitaCapacitaMassima:write:admin datiCombustibili:write:admin configuration:write configuration:read coreData:write production:read up:read settlement:read coefficientiUP:read coefficientiUP:write acl:read acl:write misurePrezziBiddingPVI:read misurePrezziBiddingPVI:write disponibilitaCapacitaMassima:read disponibilitaCapacitaMassima:write datiCombustibili:read datiCombustibili:write biddingRun:read biddingRun:write contrattoForecastVolume:write:admin acl:skipEnforcement admin",
          redirect_uri: env.callbackUrl,
        },
      });

      return CreateStore({
        auth: authModule,
        insights: appInsights,
        env,
        cridaDate: env.cridaDate,
        tideDate: env.tideDate,
        history,
        request: {
          // prettier-ignore
          get: <A,>(url: string, decoder: t.Type<A>, headers?: t.Any) => 
            tryCatch(
              () =>
                authModule
                  .getTokenSilently()
                  .then(token =>
                    ajax
                      .get(`${env.serviceUrl}/v1.0/${url}`, {
                        authorization: `Bearer ${token}`
                      })
                      .pipe(
                        RxOp.map(x => x.response),
                        RxOp.map(decoder.decode),
                        RxOp.map(E.mapLeft(errors => PathReporter.report(E.left(errors)).join('\n')))
                        )
                      .toPromise()
                  )
                  .then(
                    E.fold<string, A, Promise<A>>(
                      err => Promise.reject(err),
                      x => Promise.resolve(x)
                    )
                  ),

              (e: any) => e
            ),
          // prettier-ignore
          getXML: <A,>(url: string, decoder: t.Type<A>, headers?: t.Any) => 
            tryCatch(
              () =>
                authModule
                  .getTokenSilently()
                  .then(token =>
                   of(token)
                      .pipe(
                        RxOp.concatMap(token =>
                          ajax({
                            url: `${env.serviceUrl}/v1.0/${url}`,
                            method: 'GET',
                            responseType: "text",
                            headers: {
                              'Content-Type': 'text/xml',
                              authorization: `Bearer ${token}`
                            },
                          }),
                        ),
                        RxOp.map(x => x.response),
                        RxOp.map(decoder.decode),
                        RxOp.map(E.mapLeft(() => "parser error"))
                      )
                      .toPromise()
                  )
                  .then(
                    E.fold<string, A, Promise<A>>(
                      err => Promise.reject(err),
                      x => Promise.resolve(x)
                    )
                  ),

              (e: any) => e
            ),
          // prettier-ignore
          postXML: <A, B>(url: string, body: A, decoder: t.Type<B>) =>
            tryCatch(
              () =>
                authModule
                  .getTokenSilently()
                  .then(token =>
                    of(token)
                      .pipe(
                        RxOp.concatMap(token =>
                          ajax({
                            url: `${env.serviceUrl}/v1.0/${url}`,
                            method: "POST",
                            body: JSON.stringify(body),
                            responseType: "text",
                            headers: {
                              "Content-Type": "application/xml",
                              authorization: `Bearer ${token}`
                            }
                          })
                        ),
                        RxOp.map(x => x.response),
                        RxOp.map(decoder.decode),
                        RxOp.map(E.mapLeft(() => "parser error"))
                      )
                      .toPromise()
                  )
                  .then(
                    E.fold<string, B, Promise<B>>(
                      err => Promise.reject(err),
                      x => Promise.resolve(x)
                    )
                  ),

              (e: any) => e
            ),
          post: <A, B>(url: string, body: A, decoder: t.Type<B>) =>
            tryCatch(
              () =>
                authModule
                  .getTokenSilently()
                  .then((token) =>
                    ajax
                      .post(`${env.serviceUrl}/v1.0/${url}`, body, {
                        "Content-Type": "application/json",
                        authorization: `Bearer ${token}`,
                      })
                      .pipe(
                        RxOp.map((x) => x.response),
                        RxOp.map(decoder.decode),
                        RxOp.map(E.mapLeft(() => "parser error"))
                      )
                      .toPromise()
                  )
                  .then(
                    E.fold<string, B, Promise<B>>(
                      (err) => Promise.reject(err),
                      (x) => Promise.resolve(x)
                    )
                  ),

              (e: any) => e
            ),
          // prettier-ignore
          put: <A,>(url: string,body:any, decoder: t.Type<A>) => 
            tryCatch(
              () =>
                authModule
                  .getTokenSilently()
                  .then(token =>
                    ajax
                      .put(`${env.serviceUrl}/v1.0/${url}`, body, {
                        authorization: `Bearer ${token}`,
                        "Content-Type": "application/json"
                      })
                      .pipe(
                        RxOp.map(x => x.response),
                        RxOp.map(decoder.decode),
                        RxOp.map(E.mapLeft(() => "parser error"))
                      )
                      .toPromise()
                  )
                  .then(
                    E.fold<string, A, Promise<A>>(
                      err => Promise.reject(err),
                      x => Promise.resolve(x)
                    )
                  ),

              (e: any) => e
            )
        },
      });
    })
  )
  .subscribe((store) => {
    const render = (Component: any) => {
      ReactDOM.render(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Component />
          </ConnectedRouter>
        </Provider>,
        document.getElementById("root")
      );
    };

    if (module.hot) {
      module.hot.accept("./App.tsx", () => {
        render(App);
      });
    }
    render(App);
  });
