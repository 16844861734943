import * as t from "io-ts";
import "io-ts/lib/PathReporter";
import { PathReporter } from "io-ts/lib/PathReporter";
import { of, Observable, throwError } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map, switchMap } from "rxjs/operators";
import { fold, left } from "fp-ts/lib/Either";

export const env = t.type({
  clientID: t.string,
  domain: t.string,
  callbackUrl: t.string,
  audience: t.string,
  serviceUrl: t.string,
  appinsightsKey: t.string,
  cridaDate: t.string,
  tideDate: t.string,
  googleTagKey: t.union([t.undefined, t.string]),
});
export type env = t.TypeOf<typeof env>;

const staticConfig: Observable<t.TypeOf<typeof env>> = of({
  clientID: "GLgyE4Sm5n7BrQONPqUMMqXN2LWXwdaJ",
  domain: "axpoit.eu.auth0.com",
  callbackUrl: "http://localhost:3000",
  audience: "https://test.api.service.rtm.axpo.it/",
  serviceUrl: "https://test-rtm-service-c723ymukusxk6.azurewebsites.net",
  appinsightsKey: "9f754c85-0963-44e5-acca-0e24eea794e8",
  cridaDate: "2021-09-22",
  tideDate: "2024-12-03",
  googleTagKey: undefined,
});
const connectionStringsConfig = ajax.get("/connectionStrings.js").pipe(
  map((x) => x.response),
  map(env.decode),
  switchMap(fold((a) => throwError(PathReporter.report(left(a))), (x) => of(x)))
);

export const getEnv: Observable<t.TypeOf<typeof env>> =
  process.env.NODE_ENV === "development"
    ? staticConfig
    : connectionStringsConfig;
