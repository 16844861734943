import * as O from "fp-ts/lib/Option";
import jexcel from "jexcel";
import "jexcel/dist/jexcel.css";
import * as R from "ramda";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader";
import NotifcationModal from "../components/notifcationModal";
import PageOnLoad from "../components/pageOnLoad";
import { Selectors as TideSelectors } from "../redux/modules/tide";

import {
  Selectors,
  cancelModifyOnline,
  uploadClose,
  uploadModifyOnline,
} from "../redux/modules/Disponibilita";
import {
  firstLoadAction,
  getPVIAction,
} from "../redux/modules/productionData/production";
import { DisponibilitaCapacitaMassimaTimeserieRecord } from "../redux/modules/ragioneSocialePvi/types";
import { anyInProgess } from "../utils/request";

export default function Edit(props: {
  data: DisponibilitaCapacitaMassimaTimeserieRecord[];
}) {
  const dispatch = useDispatch();
  const disp = useSelector(Selectors.all);
  const switchDate = new Date(useSelector(TideSelectors.tideDate));

  const granularity = disp.granularity;
  const pvis: any = disp.allPvis;
  const excelContainer = React.useRef(null);
  const selectedPvis = pvis.filter(
    (x: any) => x.pvi.pvi === disp.selectedPVIStrings[0].split("|")[1]
  );
  let selectedPvi = selectedPvis[0];
  if (selectedPvis.length > 1) {
    selectedPvi = selectedPvis.filter(
      (x: any) =>
        x.ragioneSociale.partitaIva === disp.selectedPVIStrings[0].split("|")[0]
    )[0];
  }

  const excelInstance = React.useRef({
    destroy: () => {},
    setData: (a: any) => {},
    getData: (): string[][] => [],
  });

  React.useEffect(() => {
    dispatch(firstLoadAction());
    dispatch(getPVIAction());
  }, [dispatch]);

  React.useEffect(() => {
    const container = excelContainer.current;
    excelInstance.current = jexcel(container, {
      allowInsertRow: false,
      allowInsertColumn: false,
      columns: [
        { title: "PVI", width: 200, readOnly: true },
        { title: "DatetimeOffset", width: 250, readOnly: true },
        {
          type: "numeric",
          title: "Disponibilita (%)",
          width: 200,
          decimal: ",",
        },
      ],
      onbeforepaste: (el: any, val: any) => {
        return R.replace(/,/g, ".", val);
      },
    });
    return () => jexcel.destroy(container);
  }, []);

  React.useEffect(() => {
    excelInstance.current.setData(
      props.data.map((x) => [x.pvi, x.dateTimeOffset, x.disponibilita || 0])
    );
  }, [props.data, granularity]);

  return (
    <>
      <PageOnLoad customPath="ModifyOnline" />
      <div className="d-flex flex-column">
        <div
          className="backButton"
          onClick={() => dispatch(cancelModifyOnline)}
        >
          <i className="fas fa-arrow-circle-left" />
          Disponibilita
        </div>
        <div className="containerHeader"> Modifica Disponibilità </div>
        <div className="d-flex flex-row">
          <div className="detailsHeaderTitle">
            <div className="form-group  text-capitalized">Impianto</div>
            <div className="form-group text-uppercase">
              <b>{R.pathOr(null, ["selectedPVIs", 0, "nome"], disp)}</b>
            </div>
          </div>
          <div className="detailsHeaderTitle">
            <div className="form-group  text-capitalized">Dal</div>
            <div className="form-group text-uppercase">
              <b>{R.pathOr(null, ["dateSelect", "value", "start"], disp)}</b>
            </div>
          </div>
          <div className="detailsHeaderTitle">
            <div className="form-group  text-capitalized">Al</div>
            <div className="form-group text-uppercase">
              <b>{R.pathOr(null, ["dateSelect", "value", "end"], disp)}</b>
            </div>
          </div>
        </div>
      </div>
      <div className="margin-bottom-18 jexcel_hidden_index">
        <NotifcationModal
          open={disp.uploadModify}
          close={() => dispatch(uploadClose())}
          title="Upload Successful"
          message="Disponibilita Upload Successful"
        />
        <div className="spacer"></div>
        <div className="centerInFlexContainer">
          <div ref={excelContainer}></div>
        </div>
        <div>
          <button
            className="btn btn-danger rounded-pill"
            onClick={() => {
              O.fold(
                () => {
                  // Handle the case where mgp.dateSelect is None
                  console.log("No date selected");
                },
                (dateSelect: any) => {
                  // Handle the case where mgp.dateSelect is Some
                  dispatch(
                    uploadModifyOnline(
                      excelInstance.current.getData(),
                      selectedPvi.pvi.pvi,
                      selectedPvi.ragioneSociale.partitaIva,
                      dateSelect
                    )
                  );
                }
              )(disp.dateSelect);
            }}
          >
            Invia i dati inseriti
          </button>
        </div>
      </div>
      <Loader
        load={anyInProgess([
          disp.ragioneSociale,
          disp.pvis,
          disp.modifyOnline,
          disp.contractInfos,
          disp.uploadOngoing,
        ])}
      />
    </>
  );
}
