import {
  addHours,
  addMinutes,
  areIntervalsOverlapping,
  eachDayOfInterval,
  isWithinInterval,
  startOfDay,
  parse,
} from "date-fns";
import { format } from "date-fns-tz";
import { subDays } from "date-fns/esm";
import * as A from "fp-ts/lib/Array";
import * as E from "fp-ts/lib/Either";
import * as O from "fp-ts/lib/Option";
import { constFalse, constTrue } from "fp-ts/lib/function";
import { pipe } from "fp-ts/lib/pipeable";
import jexcel from "jexcel";
import "jexcel/dist/jexcel.css";
import * as R from "ramda";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/loader";
import NotifcationModal from "../components/notifcationModal";
import PageOnLoad from "../components/pageOnLoad";
import {
  cancelModifyOnline,
  hasCodiceRilevante,
  ifMI,
  Selectors,
  State,
  uploadClose,
  uploadModifyOnline,
} from "../redux/modules/MGP";
import {
  Selectors as ErrorHandlerSelector,
  setErrorAction,
} from "../redux/modules/errorHandler";
import {
  ContractInfo,
  MarketsBiddableFrom,
  MercatiBidding,
  MisurePrezziBiddingPVITimeserieRecord,
} from "../redux/modules/ragioneSocialePvi/types";
import { Selectors as TideSelectors } from "../redux/modules/tide";
import "../styles/jexcel-overrides.scss";
import { anyInProgess } from "../utils/request";
import styles from "./styles.module.scss";

const getError = (
  instance: any,
  cell: any,
  x: number,
  y: number,
  value: any,
  selectedPVIs: any
) => {
  const colNumber = x;
  const compareWith = R.contains("Q_", instance.jexcel.headers[x].title)
    ? 1
    : R.contains("P_", instance.jexcel.headers[x].title)
    ? -1
    : 0;

  const price = R.contains("P_", instance.jexcel.headers[x].title)
    ? parseInt(value)
    : instance.jexcel.options.data[y][
        compareWith + parseInt(colNumber.toString())
      ];

  const quantity = R.contains("Q_", instance.jexcel.headers[x].title)
    ? parseInt(value)
    : instance.jexcel.options.data[y][
        compareWith + parseInt(colNumber.toString())
      ];
  const isNonRilevante = R.hasPath(["codiceUpNonRilevante"], selectedPVIs[0]);
  if (quantity < 0 && price === "" && !isNonRilevante)
    return "Il prezzo deve essere valorizzato";
  if (quantity > 0 && price === "" && !isNonRilevante)
    return "Il prezzo deve essere valorizzato";
};

export default function Edit(props: {
  data: MisurePrezziBiddingPVITimeserieRecord[];
  contractInfos: ContractInfo[];
}) {
  const dispatch = useDispatch();
  const mgp = useSelector(Selectors.all);
  const error = useSelector(ErrorHandlerSelector.all);
  const granularity = mgp.granularity;
  const excelContainer = React.useRef(null);
  const switchDate = new Date(useSelector(TideSelectors.tideDate));
  const excelInstance = React.useRef({
    destroy: () => {},
    setData: (a: any) => {},
    getData: (): string[][] => [],
    getCell: (s: string): HTMLDivElement => document.createElement("div"),
  });
  const gradini = GradiniCheck(props.contractInfos);
  var isRilevante = hasCodiceRilevante(props.contractInfos);

  React.useEffect(() => {
    const container = excelContainer.current;

    const selectedMercati = pipe(
      mgp.selectedMercati,
      O.fold(() => null, (x) => x)
    );

    excelInstance.current = jexcel(container, {
      allowInsertRow: false,
      allowInsertColumn: false,
      columns: [
        { type: "hidden", title: "Date", width: 100, readOnly: true },
        { title: "Data", width: 100, readOnly: true },
        { title: "Ora", width: 60, readOnly: true },
        ...(granularity === "Quartorario"
          ? [{ title: "Quartorario", width: 100, readOnly: true }]
          : []),
        {
          type: "numeric",
          title: "Q_CAPACITY[MW]",
          width: 160,
          readOnly: true,
        },
        ...ifMI(
          mgp.selectedMercati,
          () => [
            {
              type: "numeric",
              title: "Posizione",
              width: 120,
              readOnly: true,
            },
            {
              type: "numeric",
              title: "Prezzo MGP",
              width: 120,
              readOnly: true,
            },
          ],
          () => []
        ),
        {
          type: "numeric",
          title: "Q_GR1[MW]",
          width: 120,
        },
        {
          type: "numeric",
          title: "P_GR1[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "Q_GR2[MW]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "P_GR2[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "Q_GR3[MW]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "P_GR3[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "Q_GR4[MW]",
          width: 120,
          readOnly: !gradini,
        },
        {
          type: "numeric",
          title: "P_GR4[€/MWh]",
          width: 120,
          readOnly: !gradini,
        },
      ],
      onbeforechange: (
        instance: any,
        cell: any,
        x: number,
        y: number,
        value: any
      ) => {
        const checkMessage = getError(
          instance,
          cell,
          x,
          y,
          value,
          mgp.selectedPVIs
        );
        return ifMI(
          mgp.selectedMercati,
          () =>
            value === 0 || value === ""
              ? ""
              : pipe(
                  value,
                  Number,
                  E.fromPredicate(Number.isFinite, () => {
                    const res = instance.jexcel.getData(cell);
                    return R.isEmpty(res) ? "" : res[0][0];
                  }),
                  E.getOrElse((prev) => {
                    dispatch(
                      setErrorAction({
                        details: {
                          title: "ATTENZIONE",
                          message:
                            "Utilizzare il punto come separatore decimale",
                        },
                      })
                    );
                    return prev;
                  }),
                  E.fromPredicate(
                    () => {
                      if (checkMessage) return false;
                      return true;
                    },
                    () => {
                      const res = instance.jexcel.getData(cell);
                      return R.isEmpty(res) ? "" : res[0][0];
                    }
                  ),
                  E.getOrElse((prev) => {
                    dispatch(
                      setErrorAction({
                        details: {
                          title: "ATTENZIONE",
                          message: checkMessage,
                        },
                      })
                    );
                    return prev;
                  })
                ),
          () =>
            pipe(
              value,
              Number,
              E.fromPredicate(Number.isFinite, () => {
                const res = instance.jexcel.getData(cell);
                return R.isEmpty(res) ? "" : res[0][0];
              }),
              E.getOrElse((prev) => {
                dispatch(
                  setErrorAction({
                    details: {
                      title: "ATTENZIONE",
                      message: "Utilizzare il punto come separatore decimale",
                    },
                  })
                );
                return prev;
              })
            )
        );
      },
      onbeforepaste: (el: any, val: any) => {
        return R.replace(/,/g, ".", val);
      },
      updateTable: function(
        instance: any,
        cell: any,
        col: any,
        row: any,
        val: any,
        label: any,
        cellName: any
      ) {
        const entryCol = col > ifMI(mgp.selectedMercati, () => 3, () => 1);
        const negativeVal = parseFloat(label) < 0;

        const tableData = getDataObj(
          excelInstance,
          mgp.selectedMercati,
          granularity,
          mgp.dateRange ? new Date(mgp.dateRange.start) : new Date(),
          switchDate
        );
        const marketNotSatisfied = !checkCapacityMarketSatisfiedRow(
          tableData[row],
          mgp
        );

        const isGrCol = R.includes("Q_GR", this.columns[col].title);
        cell.className = R.pipe(
          R.split(" "),
          (list: any) => {
            return marketNotSatisfied && isGrCol && val !== ""
              ? R.append("marketNotSatisfiedCell", list)
              : R.filter((c) => !R.equals("marketNotSatisfiedCell", c), list);
          },
          (list: any) =>
            entryCol && negativeVal
              ? R.append("text-danger", list)
              : R.filter((c) => !R.equals("text-danger", c), list),
          (list: any) => {
            const mercatiBiddableFrom = mgp.mercatiBiddableFrom.filter(
              (x) => x.mercatiBidding === selectedMercati
            );
            return canEditHour(
              R.pathOr([], [0], mercatiBiddableFrom) as any,
              excelInstance.current.getCell("C" + (row + 1)).textContent || "1"
            )
              ? R.filter((c) => !R.equals("readonlyCol", c), list)
              : R.append("readonlyCol", list);
          },
          R.join(" ")
        )(cell.className);
      },
    });
    return () => jexcel.destroy(container);
  }, []);

  const selectedMercati = pipe(
    mgp.selectedMercati,
    O.fold(() => null, (x) => x)
  );

  const getPosizione = (data: any, date: any) => {
    return R.pipe<any, any, any>(
      R.filter(R.propEq("dateTimeOffset", date)),
      (d) => setPosizione({ market: selectedMercati, data: d[0] || {} })
    )(data);
  };

  const posizioneCalc = ({ list, data }: { list: string[]; data: any }) => {
    const x = R.pipe(
      R.pick(list),
      R.values
    )(data);
    return R.isEmpty(data) ? null : x.reduce((a: any, b: any) => a + b, 0);
  };

  const setPosizione = ({
    market,
    data,
  }: {
    market: string | null;
    data: any;
  }) => {
    switch (market) {
      case "MI1":
        return posizioneCalc({
          list: ["mgP_Q"],
          data,
        });
      case "MI2":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q"],
          data,
        });
      case "MI3":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q"],
          data,
        });
      case "MI4":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q"],
          data,
        });
      case "MI5":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q"],
          data,
        });
      case "MI6":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q", "mI5_Q"],
          data,
        });
      case "MI7":
        return posizioneCalc({
          list: ["mgP_Q", "mI1_Q", "mI2_Q", "mI3_Q", "mI4_Q", "mI5_Q", "mI6_Q"],
          data,
        });
      default:
        return null;
    }
  };

  const getPrezzomgp = (data: any, date: any) =>
    R.prop("mgP_P")(
      R.head<any>(
        R.filter<any, any>((d: any) =>
          R.equals(date, R.prop("dateTimeOffset", d))
        )(data || [])
      )
    );
  const esitoBiddingUPData = R.pathOr([], ["esitoBiddingUPData", "value"])(mgp);
  const esitoBiddingComputedData = R.pathOr(
    [],
    ["esitoBiddingComputed", "value"]
  )(mgp);

  const market = R.pathOr("", ["selectedMercati", "value"], mgp);
  const start = R.equals(market, "MGP")
    ? new Date(
        `${R.pathOr("", ["dateSelect", "value", "start"], mgp)}T00:00:00`
      )
    : new Date(
        `${R.pathOr("", ["dateSelect", "value", "date"], mgp)}T00:00:00`
      );
  const end = R.equals(market, "MGP")
    ? new Date(`${R.pathOr("", ["dateSelect", "value", "end"], mgp)}T23:00:00`)
    : new Date(
        `${R.pathOr("", ["dateSelect", "value", "date"], mgp)}T23:00:00`
      );

  const allHourlyDates = R.pipe<any, any, any, any, any, any>(
    R.map((d: any) => R.range(0, 25).map((val) => addHours(d, val))),
    R.flatten,
    R.filter((d: any) =>
      isWithinInterval(d, {
        start,
        end,
      })
    ),
    R.map((d: Date) =>
      format(d, "yyyy-MM-dd'T'HH:mm:SSXXX", {
        timeZone: "Europe/Rome",
      })
    ),
    R.uniq
  )(
    eachDayOfInterval({
      start,
      end,
    })
  );
  interface FormattedDate {
    original: string;
    date: string;
    hour: number;
  }
  React.useEffect(() => {
    const formattedDates: FormattedDate[] = allHourlyDates.map((d: string) => ({
      original: d,
      date: format(new Date(d.split("T")[0]), "dd-MM-yyyy", {
        timeZone: "Europe/Rome",
      }),
      hour: Number(d.split("T")[1].split(":")[0]),
    }));

    var excelData = formattedDates.flatMap(({ original, date, hour }) => {
      if (granularity === "Quartorario") {
        return R.range(1, 5).map((index) => {
          const datetimeoffset = format(
            addMinutes(new Date(original), (index - 1) * 15),
            "yyyy-MM-dd'T'HH:mm:SSXXX",
            {
              timeZone: "Europe/Rome",
            }
          );
          const x = R.filter(
            R.propEq("dateTimeOffset", datetimeoffset),
            props.data
          );
          return [
            datetimeoffset,
            datetimeoffset,
            hour,
            hour * 4 + index, // Quartorario
            getQCapacity(date, mgp),
            ...ifMI(
              mgp.selectedMercati,
              () => [
                getPosizione(esitoBiddingComputedData, datetimeoffset),
                getPrezzomgp(esitoBiddingUPData, datetimeoffset),
              ],
              () => []
            ),
            R.pathOr(null, [0, "q_GR1"], x),
            R.pathOr(null, [0, "p_GR1"], x),
            R.pathOr(null, [0, "q_GR2"], x),
            R.pathOr(null, [0, "p_GR2"], x),
            R.pathOr(null, [0, "q_GR3"], x),
            R.pathOr(null, [0, "p_GR3"], x),
            R.pathOr(null, [0, "q_GR4"], x),
            R.pathOr(null, [0, "p_GR4"], x),
          ];
        });
      } else {
        const x = R.filter(R.propEq("dateTimeOffset", original), props.data);
        return [
          [
            original,
            date,
            hour,
            getQCapacity(date, mgp),
            ...ifMI(
              mgp.selectedMercati,
              () => [
                getPosizione(esitoBiddingComputedData, original),
                getPrezzomgp(esitoBiddingUPData, original),
              ],
              () => []
            ),
            R.pathOr(null, [0, "q_GR1"], x),
            R.pathOr(null, [0, "p_GR1"], x),
            R.pathOr(null, [0, "q_GR2"], x),
            R.pathOr(null, [0, "p_GR2"], x),
            R.pathOr(null, [0, "q_GR3"], x),
            R.pathOr(null, [0, "p_GR3"], x),
            R.pathOr(null, [0, "q_GR4"], x),
            R.pathOr(null, [0, "p_GR4"], x),
          ],
        ];
      }
    });

    excelInstance.current.setData(excelData);
  }, [props.data, esitoBiddingUPData, esitoBiddingComputedData]);
  return (
    <>
      <PageOnLoad customPath="ModifyOnline" />
      {R.when(
        (x) => R.equals(x, false) || R.isNil(x),
        () => (
          <NotifcationModal
            open={mgp.uploadModify}
            close={() => dispatch(uploadClose())}
            title="Upload Successful"
            message={
              <>
                {mgp.isCapacityMarketSatisfied ? null : (
                  <div className="isCapacityMarketSatisfied">
                    Rischio Capacity Market Non Rispettato
                  </div>
                )}
                <div>MGP & MI Upload Successful</div>
              </>
            }
          />
        )
      )(error.error)}

      <div>
        <div className="d-flex flex-column">
          <div
            className="backButton"
            onClick={() => dispatch(cancelModifyOnline)}
          >
            <i className="fas fa-arrow-circle-left" />
            MGP & MI
          </div>
          <div className="containerHeader"> Modifica MGP&MI </div>
          <div className="d-flex flex-row">
            <div className="detailsHeaderTitle">
              <div className="form-group text-capitalized">Mercato</div>
              <div className="form-group text-uppercase">
                <b>{R.pathOr(null, ["selectedMercati", "value"], mgp)}</b>
              </div>
            </div>
            <div className="detailsHeaderTitle">
              <div className="form-group text-capitalized">Impianto</div>
              <div className="form-group text-capitalized">
                <b>{R.pathOr(null, ["selectedPVIs", 0, "nome"], mgp)}</b>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`margin-bottom-18 jexcel_hidden_index ${styles.modifyTable}`}
        >
          <div className="spacer"></div>
          <div className="centerInFlexContainer">
            <div ref={excelContainer}></div>
          </div>
          <div>
            Per le unità abilitate al Capacity Market: Cella Colorata significa
            “Rischio Capacity Market Non Rispettato”
          </div>
          <br></br>
          <div>
            <button
              className="btn btn-danger rounded-pill"
              onClick={() => {
                return pipe(
                  mgp.selectedMercati,
                  O.chain((m) => (m !== "MGP" ? O.some(m) : O.none)),
                  O.fold(
                    () => {
                      if (
                        checkDataQuartorario(
                          excelInstance,
                          mgp.selectedMercati,
                          granularity,
                          mgp.dateRange
                            ? new Date(mgp.dateRange.start)
                            : new Date(),
                          switchDate
                        )
                      ) {
                        dispatch(
                          uploadModifyOnline(
                            getData(
                              excelInstance,
                              mgp.selectedMercati,
                              granularity,
                              mgp.dateRange
                                ? new Date(mgp.dateRange.start)
                                : new Date(),
                              switchDate
                            ),
                            market,
                            isRilevante,
                            props.contractInfos[0].upsa || false
                          )
                        );
                      } else {
                        dispatch(
                          setErrorAction({
                            details: {
                              title: "ATTENZIONE",
                              message:
                                "Tutti i qh di ciascuna h devono avere medesima quantità e prezzo",
                              btnTitle: "Ok",
                            },
                          })
                        );
                      }
                    },
                    () => {
                      const tableData = getDataObj(
                        excelInstance,
                        mgp.selectedMercati,
                        granularity,
                        mgp.dateRange
                          ? new Date(mgp.dateRange.start)
                          : new Date(),
                        switchDate
                      );
                      const checkQGR = qgrCheck({
                        data: tableData,
                      });
                      if (checkQGR) {
                        dispatch(
                          setErrorAction({
                            details: {
                              title: "ATTENZIONE",
                              message:
                                "Le ore senza quantità o con quantità = 0 saranno ignorate",
                              btnTitle: "Ok",
                            },
                          })
                        );
                      }

                      return dispatch(
                        uploadModifyOnline(
                          getData(
                            excelInstance,
                            mgp.selectedMercati,
                            granularity,
                            mgp.dateRange
                              ? new Date(mgp.dateRange.start)
                              : new Date(),
                            switchDate
                          ),
                          market,
                          isRilevante,
                          props.contractInfos[0].upsa || false
                        )
                      );
                    }
                  )
                );
              }}
            >
              Invia dati inseriti
            </button>
          </div>
        </div>
      </div>
      <Loader
        load={anyInProgess([
          mgp.ragioneSociale,
          mgp.pvis,
          mgp.market,
          mgp.contractInfos,
          mgp.modifyOnline,
          mgp.esitoBiddingComputed,
          mgp.uploadOngoing,
          mgp.capacityMarket,
          mgp.marketConfigPVIInfo,
        ])}
      />
    </>
  );
}

/*const valuePGRChecker = (row: any, index: number) => {
  const q_gr = R.prop(`q_GR${index}`, row);
  const p_gr = R.prop(`p_GR${index}`, row);

  // Removed in https://dev.azure.com/arklabita/ProjectONE/_workitems/edit/8432
  // In comments
  // if (!R.isEmpty(q_gr) && p_gr <= 0) return false;
  if (q_gr < 0 && p_gr <= 0) return false;
  if (q_gr > 0 && p_gr < 0) return false;

  if (R.isEmpty(q_gr) && R.isEmpty(p_gr)) return true;
  if (R.isNil(q_gr) && R.isNil(p_gr)) return true;

  return true;
};

const pgrCheck = ({ data }: { data: any }) =>
  R.pipe<any, any, any>(
    R.map((row: any) =>
      R.any(R.equals(false))([
        valuePGRChecker(row, 1),
        valuePGRChecker(row, 2),
        valuePGRChecker(row, 3),
        valuePGRChecker(row, 4),
      ])
    ),
    R.any(R.equals(true))
  )(data);
*/

const valueQGRChecker = (row: any, index: number) => {
  const q_gr = R.prop(`q_GR${index}`, row);
  const p_gr = R.prop(`p_GR${index}`, row);

  if (!R.isEmpty(p_gr) && (q_gr === 0 || q_gr === "")) return false;

  if (R.isEmpty(q_gr) && R.isEmpty(p_gr)) return true;
  if (R.isNil(q_gr) && R.isNil(p_gr)) return true;

  return true;
};

const qgrCheck = ({ data }: { data: any }) =>
  R.pipe<any, any, any>(
    R.map((row: any) =>
      R.any(R.equals(false))([
        valueQGRChecker(row, 1),
        valueQGRChecker(row, 2),
        valueQGRChecker(row, 3),
        valueQGRChecker(row, 4),
      ])
    ),
    R.any(R.equals(true))
  )(data);

const GradiniCheck = (contracts: ContractInfo[]) =>
  pipe(
    contracts,
    A.chain((c) => c.fornitura || null),
    A.findFirst((x) => x.caricamentoMIGradini || x.caricamentoMGPGradini),
    O.fold(constFalse, constTrue)
  );

function checkDataQuartorario(
  excelInstance: any,
  selectedMercati: O.Option<MercatiBidding>,
  granularity: "Orario" | "Quartorario" | "Misto",
  mgpStartDate: Date,
  switchDate: Date
) {
  const ret = getData(
    excelInstance,
    selectedMercati,
    granularity,
    mgpStartDate,
    switchDate
  );
  // Group data by date and hour
  const groupedByDateHour = R.groupBy((row: any) =>
    format(new Date(row[0]), "yyyy-MM-dd'T'HH")
  )(ret);

  // Check if all q_GR and p_GR values are the same within each group
  const isValid = Object.values(groupedByDateHour).every((group: any) => {
    const [firstRow, ...restRows] = group;
    return restRows.every((row: any) =>
      [
        "q_GR1",
        "q_GR2",
        "q_GR3",
        "q_GR4",
        "p_GR1",
        "p_GR2",
        "p_GR3",
        "p_GR4",
      ].every((key, index) => row[index + 1] === firstRow[index + 1])
    );
  });

  return isValid;
}
function getData(
  excelInstance: any,
  selectedMercati: O.Option<MercatiBidding>,
  granularity: "Orario" | "Quartorario" | "Misto",
  mgpStartDate: Date,
  switchDate: Date
) {
  let data = excelInstance.current.getData();

  if (granularity === "Orario" && mgpStartDate >= switchDate) {
    data = data.flatMap((row: any) => {
      const minuteOffsets = [0, 15, 30, 45];
      return minuteOffsets.map((offset) => {
        const newDateTimeOffset = format(
          addMinutes(new Date(row[0]), offset),
          "yyyy-MM-dd'T'HH:mm:ssXXX",
          {
            timeZone: "Europe/Rome",
          }
        );
        return [newDateTimeOffset, ...row.slice(1)];
      });
    });
  }
  const ret = data.map((row: any) =>
    ifMI(
      selectedMercati,
      () => {
        if (granularity === "Quartorario") {
          const [
            dateTimeOffest,
            ,
            ,
            ,
            q_Capacity,
            posizione,
            ,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          const ret = [
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
            posizione,
          ];

          return ret;
        } else {
          const [
            dateTimeOffest,
            ,
            ,
            q_Capacity,
            posizione,
            ,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          const ret = [
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
            posizione,
          ];

          return ret;
        }
      },
      () => {
        if (granularity === "Quartorario") {
          const [
            dateTimeOffest,
            ,
            ,
            ,
            q_Capacity,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          const ret = [
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
          ];

          return ret;
        } else {
          const [
            dateTimeOffest,
            ,
            ,
            q_Capacity,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          const ret = [
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
          ];
          return ret;
        }
      }
    )
  );

  return ret;
}

function getDataObj(
  excelInstance: any,
  selectedMercati: O.Option<MercatiBidding>,
  granularity: "Orario" | "Quartorario" | "Misto",
  mgpStartDate: Date,
  switchDate: Date
) {
  let data = excelInstance.current.getData();

  if (granularity === "Orario" && switchDate > mgpStartDate) {
    console.log("ENTRATO");
    data = data.flatMap((row: any) => {
      const minuteOffsets = [0, 15, 30, 45];
      return minuteOffsets.map((offset) => {
        const newDateTimeOffset = format(
          addMinutes(new Date(row[0]), offset),
          "yyyy-MM-dd'T'HH:mm:ssXXX",
          {
            timeZone: "Europe/Rome",
          }
        );
        return [newDateTimeOffset, ...row.slice(1)];
      });
    });
  }

  return data.map((row: any) =>
    ifMI(
      selectedMercati,
      () => {
        if (granularity === "Quartorario") {
          const [
            dateTimeOffest,
            ,
            ,
            ,
            q_Capacity,
            posizione,
            ,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          return {
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
            posizione,
          };
        } else {
          const [
            dateTimeOffest,
            ,
            ,
            q_Capacity,
            posizione,
            ,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          return {
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
            posizione,
          };
        }
      },
      () => {
        if (granularity === "Quartorario") {
          const [
            dateTimeOffest,
            ,
            ,
            ,
            q_Capacity,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          return {
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
          };
        } else {
          const [
            dateTimeOffest,
            ,
            ,
            q_Capacity,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
          ] = row;
          return {
            dateTimeOffest,
            q_GR1,
            p_GR1,
            q_GR2,
            p_GR2,
            q_GR3,
            p_GR3,
            q_GR4,
            p_GR4,
            q_Capacity,
          };
        }
      }
    )
  );
}

function canEditHour(market: MarketsBiddableFrom, hour: string) {
  return market.biddableFrom === "00" ? true : hour >= market.biddableFrom;
}

function checkCapacityMarketSatisfiedRowList(tableData: any, state: State) {
  return tableData.map((row: any) =>
    checkCapacityMarketSatisfiedRow(row, state)
  );
}
const stringChecker = (a: any) => (R.isEmpty(a) ? 0 : a);

//this logic is duplicated
function checkCapacityMarketSatisfiedRow(row: any, state: State) {
  const q_GR1 = stringChecker(R.pathOr(0, ["q_GR1"], row));
  const q_GR2 = stringChecker(R.pathOr(0, ["q_GR2"], row));
  const q_GR3 = stringChecker(R.pathOr(0, ["q_GR3"], row));
  const q_GR4 = stringChecker(R.pathOr(0, ["q_GR4"], row));
  const p_GR1 = stringChecker(R.pathOr(0, ["p_GR1"], row));
  const p_GR2 = stringChecker(R.pathOr(0, ["p_GR2"], row));
  const p_GR3 = stringChecker(R.pathOr(0, ["p_GR3"], row));
  const p_GR4 = stringChecker(R.pathOr(0, ["p_GR4"], row));
  const posizione = stringChecker(R.pathOr(0, ["posizione"], row));
  const q_Capacity_Val = R.pathOr(null, ["q_Capacity"], row);
  const q_Capacity = q_Capacity_Val === "" ? null : q_Capacity_Val;

  if (R.isNil(q_Capacity)) return true;

  const capacityMarketArr = R.pathOr(
    null,
    ["capacityMarket", "value"],
    state
  ) as any;

  //use capacitymarket config, a SINGLE record {"type":"CapacityMarket","table":[{"id":1,"p_Ven":0.0000000000,"p_Acq":3000.0000000000}]}
  //not by pvi
  //const pvi = R.pathOr(null, ["selectedPVIs", 0, "pvi"], state);

  const capacityMarketcfg = capacityMarketArr.filter((x: any) => x.id === 1)[0];

  const selectedMercati = pipe(
    state.selectedMercati,
    O.fold(() => null, (x) => x)
  );

  if (selectedMercati === "MGP") {
    //#8695
    //const q_GR_Sum = q_GR1 + q_GR2 + q_GR3 + q_GR4;
    const q_GR_Sum = q_GR1;
    //#8432
    return !(q_GR_Sum < q_Capacity);
  }

  const q_GR1_OFF_VEN = q_GR1 > 0 ? q_GR1 : 0;
  const q_GR2_OFF_VEN = q_GR2 > 0 ? q_GR2 : 0;
  const q_GR3_OFF_VEN = q_GR3 > 0 ? q_GR3 : 0;
  const q_GR4_OFF_VEN = q_GR4 > 0 ? q_GR4 : 0;
  const q_GR1_OFF_ACQ = q_GR1 < 0 ? q_GR1 : 0;
  const q_GR2_OFF_ACQ = q_GR2 < 0 ? q_GR2 : 0;
  const q_GR3_OFF_ACQ = q_GR3 < 0 ? q_GR3 : 0;
  const q_GR4_OFF_ACQ = q_GR4 < 0 ? q_GR4 : 0;
  const p_Ven = R.pathOr(0, ["p_Ven"], capacityMarketcfg);
  const p_Acq = R.pathOr(0, ["p_Acq"], capacityMarketcfg);
  const tot_Quantity =
    posizione +
    (p_GR1 <= p_Ven ? q_GR1_OFF_VEN : 0) +
    (p_GR2 <= p_Ven ? q_GR2_OFF_VEN : 0) +
    (p_GR3 <= p_Ven ? q_GR3_OFF_VEN : 0) +
    (p_GR4 <= p_Ven ? q_GR4_OFF_VEN : 0) +
    (p_GR1 >= p_Acq ? q_GR1_OFF_ACQ : 0) +
    (p_GR2 >= p_Acq ? q_GR2_OFF_ACQ : 0) +
    (p_GR3 >= p_Acq ? q_GR3_OFF_ACQ : 0) +
    (p_GR4 >= p_Acq ? q_GR4_OFF_ACQ : 0);

  //#8432
  return !(tot_Quantity < q_Capacity);
}

const getQCapacity = (date: string, state: State) => {
  const mcpi = R.pathOr([], ["marketConfigPVIInfo", "value"], state);
  const pvi = R.pathOr(null, ["selectedPVIs", 0, "pvi"], state);
  const config = mcpi.filter((x: any) => x.pvi === pvi);
  const marketConfig = R.pathOr(null, [0], config) as any;
  if (R.isNil(marketConfig) || R.isNil(date) || R.isEmpty(date)) return null;

  // Parse the date string
  const parsedDate = parse(date, "dd-MM-yyyy", new Date());
  if (isNaN(parsedDate.getTime())) {
    console.log("Invalid date format:", date);
    return null;
  }

  // Get Market Config PVI Info for the date
  // Can only return 1 or none
  const dMinus1 = subDays(parsedDate, 1);

  const isValidDate = (date: any) => !isNaN(Date.parse(date));

  const overlap = marketConfig.quantityPeriods.filter((x: any) => {
    const fromDate = new Date(x.from);
    const toDate = new Date(x.to);

    if (!isValidDate(fromDate) || !isValidDate(toDate)) {
      return false;
    }

    return areIntervalsOverlapping(
      { start: startOfDay(fromDate), end: startOfDay(toDate) },
      { start: dMinus1, end: dMinus1 }
    );
  });

  return R.pathOr(null, [0, "value"], overlap) as any;
};
